import { useState } from 'react';

import { listFileTags } from 'services/fileManager/fileManagerServices';

export default function useGetTags(case_id = '') {
  const [fullTags, setFullTags] = useState([]);

  const getFileTags = async () => {
    listFileTags(case_id)
      .then((response) => {
        if (response && response?.data) {
          setFullTags(response.data?.['file-tags']);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    getFileTags,
    fullTags,
  };
}

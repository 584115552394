import { useState, useRef } from 'react';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import PrintFile from '../../ListView/Templates/ActionsTemplate/PrintFile';
import GetSignature from '../../ListView/Templates/ActionsTemplate/GetSignature';
// import PdfUrlViewer from './../../../../pdfViewer/PdfUrlViewer';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

export default function DocumentViewer(props) {
  const [setInstance] = useState(null);
  const [setPSPDFKit] = useState(false);
  const fileType = props?.item?.file_type;
  const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const printFileFormats = ['image/png', 'application/pdf', docTypeVal];
  const windowRef = useRef();
  const [scale, setScale] = useState(1);

  const sigCondition = fileType && (fileType === 'application/pdf' || fileType === docTypeVal);

  return (
    <>
      {props?.document ? (
        <div>
          {!props?.isClientFileManager && sigCondition && <GetSignature rowData={props?.item} />}
          <FileDownload
            rowData={props?.item}
            isFromFilePreviewModal={true}
            filePreviewModalIconClassName={'icon-save p-2 pointer float-end h2 mb-0'}
          />
          {fileType && printFileFormats?.includes(fileType?.toLowerCase()) ? (
            <PrintFile
              rowData={props?.item}
              isFromFilePreviewModal={true}
              filePreviewModalIconClassName={'icon-Print p-2 pointer float-end h2 mb-0'}
            />
          ) : null}
          {/* <div style={{ height: '90vh' }} className="d-flex align-items-center justify-content-center">
            <div style={{ width: '85%', height: '' }}>
              {
                 console.log(props?.document, "JJJJJ")
              }
           


              
          {
                    props?.document &&
                    <PdfUrlViewer
                    url={props?.document}
                    scale={scale} 
                    windowRef={windowRef}
                    />
                  }
            </div>
          </div> */}
          <div className="w-100" style={{ height: '90vh' }}>
            <embed
              // src="https://lawft-document-storage-develop.s3.amazonaws.com/public/file_manager/58a51d4d-a0b2-41c5-b557-b20539d23972/0eeb7d1f-9b81-441b-b83b-fae0d8c5e8bf/ba052d13-f9bc-4b29-adc7-7eb4f247d291.pdf?AWSAccessKeyId=AKIAW2H5LIX7MCRRCCY5&Signature=4tZSZGxu0VrIFUL4xz9Xfc%2FXZZo%3D&Expires=1728567440"
              src={props?.document + '#toolbar=0&navpanes=0&scrollbar=0'}
              // src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
            ></embed>
          </div>

          {/* <PdfViewerComponent
            document={props?.document}
            setPSPDFKit={setPSPDFKit}
            setInstance={setInstance}
            signatureOption={true}
            assignee={''}
            clientOptions={[]}
            onLoad={props?.onLoad}
          /> */}
        </div>
      ) : null}
    </>
  );
}

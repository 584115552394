import { useState, useRef, useEffect } from 'react';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import PrintFile from '../../ListView/Templates/ActionsTemplate/PrintFile';
import GetSignature from '../../ListView/Templates/ActionsTemplate/GetSignature';
// import PdfUrlViewer from './../../../../pdfViewer/PdfUrlViewer';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import * as docx from 'docx-preview';
import useSpinner from 'hooks/useSpinner';

export default function DocumentViewer(props) {
  const [setInstance] = useState(null);
  const [setPSPDFKit] = useState(false);
  const [iFrameUrl, setIframeUrl] = useState('');
  const fileType = props?.item?.file_type;
  const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const printFileFormats = ['image/png', 'application/pdf', docTypeVal];
  const windowRef = useRef();
  const [scale, setScale] = useState(1);
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading]);

  const sigCondition = fileType && (fileType === 'application/pdf' || fileType === docTypeVal);

  useEffect(() => {
    let encodedUrl = encodeURIComponent(props.document);
    let _iFrameUrl = 'https://docs.google.com/viewer?url=' + encodedUrl + '&embedded=true';
    setIframeUrl(_iFrameUrl);
  }, [props.document]);

  useEffect(() => {
    if (props.document) {
      setIsLoading(true);
      fetch(props.document)
        .then((res) => {
          const template = res.arrayBuffer();
          docx.renderAsync(template, document.getElementById('panel-section')).then((x) => console.log('docx: finished'));
          console.log('buffer: ', template);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [props.document]);

  return (
    <>
      {props?.document ? (
        <div>
          {!props?.isClientFileManager && sigCondition && <GetSignature rowData={props?.item} />}
          <FileDownload
            rowData={props?.item}
            isFromFilePreviewModal={true}
            filePreviewModalIconClassName={'icon-save p-2 pointer float-end h2 mb-0'}
          />
          {/* <div
        id="panel-section"
        style={{ height: "800px", overflow: "scroll" }}
      /> */}

          {/* <div className="w-100" style={{ height: '90vh' }}> */}

          {/* <iframe src={iFrameUrl} frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"></iframe> */}

          <iframe
            src={iFrameUrl}
            //   src="https://view.officeapps.live.com/op/view.aspx?
            //   src=https%3A%2F%2Fpdftron.s3.amazonaws.com%2Fdownloads%2Fpl%2FQuote_.Word.docx
            //   &wdOrigin=BROWSELINK"
            //   src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.document}`}
            width="1366px"
            height="623px"
            frameborder="0"
          >
            This is an embedded{' '}
            <a target="_blank" href="http://office.com">
              Microsoft Office
            </a>{' '}
            document, powered by{' '}
            <a target="_blank" href="http://office.com/webapps">
              Office Online
            </a>
            .
          </iframe>

          {/* <embed
            // src="https://lawft-document-storage-develop.s3.amazonaws.com/public/file_manager/58a51d4d-a0b2-41c5-b557-b20539d23972/0eeb7d1f-9b81-441b-b83b-fae0d8c5e8bf/ba052d13-f9bc-4b29-adc7-7eb4f247d291.pdf?AWSAccessKeyId=AKIAW2H5LIX7MCRRCCY5&Signature=4tZSZGxu0VrIFUL4xz9Xfc%2FXZZo%3D&Expires=1728567440"
            src={props?.document+"#toolbar=0&navpanes=0&scrollbar=0"}
            // src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
        ></embed> */}
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';

import ContactForm from './components/ContactForm/ContactForm';
import CaseInformation from './components/CaseInformation/CaseInformation';
import FeeAndPayments from './components/FeePayment/FeePayment';

import { totalCountEvent } from 'services/generalServices';
import { useLeadContext } from '../Context/LeadContext';
import { getCustomFields } from 'services/Settings';
import { createContact } from 'services/contact';
import { createLead } from 'services/lead/leadServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { prepareBillingData } from '../helpers/helpers';

const tabs = [{ label: 'Contacts' }, { label: 'Information' }, { label: 'Billing preferences' }];

const AddNewLead = ({ handleClose }) => {
  const { handleSubmit, ...formProps } = useForm();
  const { addToast } = useToast();

  const {
    paymentStructures,
    leadStages,
    practiceAreaList,
    caseMatterList,
    leadSourceList,
    officeLocationList,
    courtList,
    loadCourtContacts,
    loadLeadSources,
    businessRole,
    relationRole,
    loadTotalCount,
    venueOptions,
    loadVenue,
    loadLeadReferrals,
    leadReferralsList,
  } = useLeadContext();

  const [activeIndex, setActiveIndex] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedContact, setSelectedContact] = useState({});
  const [duplicateContact, setDuplicateContact] = useState('');
  const [conflictCheckResult, setConflictCheckResult] = useState({});

  useEffect(() => {
    const loadCustomFields = async () => {
      try {
        const response = await totalCountEvent('custom-field');
        const res = await getCustomFields('', response.data);
        setCustomFields(res.data?.['custom-field']);
      } catch (err) {
        console.error(err);
      }
    };
    loadCustomFields();
  }, []);

  //To set Default value for office Location field
  useEffect(() => {
    if (officeLocationList?.length > 0) {
      const officeLoc = officeLocationList?.find((data) => data.is_primary_office === 'true')?.office_name;
      formProps.setValue('case_description.office_location', officeLoc);
    }
  }, [officeLocationList]);

  //To set Default value for lead stage field
  useEffect(() => {
    if (leadStages?.length > 0) {
      const newStageSk = leadStages?.find((data) => data.lead_stage_name === 'New')?.sk;
      formProps.setValue('leadStageSk', newStageSk);
    }
  }, [leadStages]);

  const onNextClick = (formData) => {
    if (activeIndex === 2) {
      createLeadHandle(formData);
    } else {
      setActiveIndex((preVal) => preVal + 1);
    }
  };

  const createLeadHandle = async (data) => {
    let newContacts = [];
    let oldContacts = [];
    if (data?.contact?.first_name && !!data?.contact?.isOld === false) {
      newContacts.push(
        new Promise((resolve, reject) => {
          createContact({ ...data?.contact, contact_type: 'person' }, true)
            .then((response) => {
              resolve({
                ...response.data,
                ...data?.contact,
                type: data?.client?.first_name ? 'on-behalf' : 'lead',
                contact_type: 'person',
              });
            })
            .catch((error) => reject(error));
        })
      );
    } else if (data?.contact?.isOld) {
      oldContacts.push({ ...data?.contact, type: data?.client?.first_name ? 'on-behalf' : 'lead' });
    }
    if (data?.client?.first_name && !!data?.client?.isOld === false) {
      newContacts.push(
        new Promise((resolve, reject) => {
          createContact({ ...data?.client, contact_type: 'person' }, true)
            .then((response) => {
              resolve({
                ...response.data,
                ...data?.client,
                type: 'lead',
                contact_type: 'person',
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    } else if (data?.client?.isOld) {
      oldContacts.push({ ...data?.client, type: 'lead' });
    }
    const contacts = await Promise.all(newContacts);

    // const _contacts = [...data?.relations, ...contacts, ...oldContacts];
    const contactsWithoutRole = [...data?.relations, ...contacts, ...oldContacts].filter((contact) => !contact?.role);
    const clientId = contactsWithoutRole.length > 0 ? contactsWithoutRole[0].contact_id : null;
    const _contacts = [...data?.relations, ...contacts, ...oldContacts].map((contact) => {
      if (contact?.role) {
        return { ...contact, client_id: clientId };
      } else {
        return contact;
      }
    });

    const leadDetails = { ...data?.lead_details };
    if (leadDetails.source !== 'Referral') {
      leadDetails.gratitude = null;
      leadDetails.gratitude_item = '';
      leadDetails.referred_from = null;
    }

    const formData = {
      lead_stage: data?.leadStageSk ?? '',
      case_description: {
        ...data?.case_description,
        potential_case_value: data?.case_description?.potential_case_value?.toString(),
        case_matter: data?.case_description?.case_matter || '',
      },
      lead_details: { ...leadDetails, tags: leadDetails.tags ?? [] },
      contact: _contacts,
      lead_assigned_to: data?.lead_assigned_to ? [data?.lead_assigned_to] : [],
      billing_preferences: prepareBillingData(data?.billing_preferences),
    };
    if (_contacts.find((val) => val.is_billing_contact)) {
      formData.billing_contact = _contacts.find((val) => val.is_billing_contact).sk;
    }
    createLead(formData)
      .then(() => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CREATE_SUCCESS);
        loadTotalCount();
        handleClose('created');
      })
      .catch((error) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CREATE_FAILURE);
        console.error(error);
      });
  };

  const onPrevClick = () => setActiveIndex((preVal) => preVal - 1);

  useEffect(() => {
    if (customFields) {
      formProps.setValue(
        'contact.custom_field',
        customFields.filter((v) => v.module === 'contact')
      );
      formProps.setValue(
        'case_description.custom_field',
        customFields.filter((v) => v.module === 'case')
      );
    }
  }, [customFields]);

  return (
    <Sidebar visible position="right" fullScreen onHide={handleClose} showCloseIcon={false}>
      <div className="d-flex justify-content-between align-items-center mb-3  flex-wrap">
        <div className="d-flex align-items-center justify-content-start">
          <div className="icon-box me-2 me-md-4 pointer" onClick={handleClose}>
            <i className="icon-back" />
          </div>
          <h6 className="sub-title">Add New Lead</h6>
        </div>
        {/* top buttons */}
        <div className="d-flex justify-content-end add-lead-buttons">
          <Button type="button" disabled={activeIndex === 0} className="p-button p-button-secondary me-2" onClick={onPrevClick}>
            Previous Step
          </Button>
          <Button type="button" className="p-button p-button-secondary" onClick={handleSubmit(onNextClick)}>
            {activeIndex && activeIndex === 2 ? 'Add Lead' : 'Next Step'}
          </Button>
        </div>
      </div>

      <div className="case-sidebar addnew-lead pe-lg-0 me-lg-0">
        <Steps model={tabs} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} className="cases-steps" />
        <div className="container-fluid px-0 mx-0">
          {activeIndex === 0 && (
            <ContactForm
              {...{ handleSubmit, businessRole, relationRole, customFields }}
              {...{ currentTabIndex, setCurrentTabIndex, selectedContact, setSelectedContact, duplicateContact, setDuplicateContact }}
              {...formProps}
            />
          )}
          {activeIndex === 1 && (
            <CaseInformation
              {...formProps}
              {...{
                handleSubmit,
                leadStages,
                loadLeadSources,
                leadSourceList,
                customFields,
                practiceAreaList,
                caseMatterList,
                officeLocationList,
                courtList,
                venueOptions,
                loadCourtContacts,
                loadVenue,
                conflictCheckResult,
                setConflictCheckResult,
                loadLeadReferrals,
                leadReferralsList,
              }}
            />
          )}
          {activeIndex === 2 && <FeeAndPayments {...{ paymentStructures, handleSubmit }} {...formProps} />}
        </div>
      </div>

      {/* footer button */}
      {activeIndex !== 2 && (
        <div className="footer-btn">
          <Button type="button" disabled={activeIndex === 0} className="p-button p-button-secondary me-2" onClick={onPrevClick}>
            Previous Step
          </Button>
          <Button type="button" className="p-button p-button-secondary" onClick={handleSubmit(onNextClick)}>
            {activeIndex && activeIndex === 2 ? 'Add Lead' : 'Next Step'}
          </Button>
        </div>
      )}
    </Sidebar>
  );
};

export default AddNewLead;

import { format } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';

export const dateTemplate = (params) => {
  const { rowData, props, timeEntiresError } = params || {};
  const data = rowData.time_entry_date;
  return (
    <>
      <span className="p-column-title text-break">Date</span>
      <span className="text-break">{data ? format(new Date(data), 'MM/dd/yyyy') : <i>Please Enter</i>}</span>
      <br></br>
      {timeEntiresError && timeEntiresError[`${props.rowIndex}_date`] && (
        <span className="text-danger">{timeEntiresError[`${props.rowIndex}_date`]}</span>
      )}
    </>
  );
};

export const timeEntryDataTableFooterKeyTemplate = (params) => {
  const { isEvergreenOrStandardRetainer, taxPercentage } = params || {};
  return (
    <div>
      <div>Total</div>
      {isEvergreenOrStandardRetainer && taxPercentage && (
        <>
          <div className="mt-2">Tax %</div>
          <div className="mt-2">Total + Tax</div>
        </>
      )}
    </div>
  );
};

export const caseNameBody = (params) => {
  const { caseName } = params || {};
  return (
    <>
      <span className="p-column-title text-break">Case Name</span>

      <span className="text-break">{caseName || '-'}</span>
    </>
  );
};

export const actionBody = (params) => {
  const { rowData } = params || {};

  return (
    <>
      <span className="p-column-title text-break">Activity</span>
      <span className="text-break">{rowData?.module_name ? rowData?.module_name : '-'}</span>
    </>
  );
};

export const teamMemberBody = (params) => {
  const { rowData, props, timeEntiresError } = params || {};
  return (
    <>
      <span className="p-column-title text-break">Team Member</span>
      <span className="text-break">{rowData.team_member_name ? rowData.team_member_name : <i>Please Enter</i>}</span>
      <br></br>
      {timeEntiresError && timeEntiresError[`${props.rowIndex}_team_member`] && (
        <span className="text-danger">{timeEntiresError[`${props.rowIndex}_team_member`]}</span>
      )}
    </>
  );
};

export const teamMemberEditTimeEntries = (params) => {
  const { props, teamMembers, timeEntiresError, timeEntires, onChangeTeamMemberEditTimeEntries } = params || {};
  return (
    <>
      <Dropdown
        options={teamMembers}
        optionLabel="name"
        placeholder="Select"
        value={{
          name: props.rowData?.team_member_name,
          contact_id: props.rowData?.team_member_id,
        }}
        onChange={(e) => {
          let index = props.rowIndex;
          onChangeTeamMemberEditTimeEntries(e, index);
        }}
        filter
        showFilterClear={true}
        showClear={timeEntires[props?.rowIndex].team_member_name && timeEntires[props?.rowIndex].team_member_id ? true : false}
      />
      {timeEntiresError && timeEntiresError[`${props.rowIndex}_team_member`] && (
        <span className="text-danger">{timeEntiresError[`${props.rowIndex}_team_member`]}</span>
      )}
    </>
  );
};

export const durationTemplate = (params) => {
  const { rowData, props, timeEntiresError } = params || {};
  let timeData = rowData?.total_times?.split(':') ?? 'No Data';
  if (timeData !== 'No Data') {
    let data = timeData[0] + 'h ' + timeData[1] + 'm ' + timeData[2] + 's ';
    timeData = data;
  }
  return (
    <>
      <span className="p-column-title text-break">Duration</span>
      <span className="text-break">{rowData.total_hours ? rowData.total_hours + ' hrs' : <i>Please Enter</i>}</span>
      <br></br>
      {timeEntiresError && timeEntiresError[`${props.rowIndex}_total_hours`] && (
        <span className="text-danger">{timeEntiresError[`${props.rowIndex}_total_hours`]}</span>
      )}
    </>
  );
};

export const totalHoursTemplate = (totalHours) => {
  return <div className="text-bold">{`${totalHours} hrs`}</div>;
};

export const timeEntryDataTableFooterValueTemplate = (params) => {
  const { isEvergreenOrStandardRetainer, taxPercentage, timeEntriesTotal, rateWithTax } = params || {};
  return (
    <div>
      <div className="text-bold">{formatNumbersToCurrencyString(timeEntriesTotal)}</div>
      {isEvergreenOrStandardRetainer && taxPercentage && (
        <>
          <div className="mt-2">{`${taxPercentage}%`}</div>
          <div className="mt-2">{formatNumbersToCurrencyString(rateWithTax)}</div>
        </>
      )}
    </div>
  );
};

export const totalBody = (rowData) => {
  let hour_rate = rowData.hour_rate;
  let total_hours = rowData.total_hours;
  hour_rate = hour_rate ? parseFloat(hour_rate) : 0;
  total_hours = total_hours ? parseFloat(total_hours) : 0;
  let total = hour_rate * total_hours;
  return (
    <>
      <span className="p-column-title text-break">Total</span>
      <span className="text-break">{formatNumbersToCurrencyString(fixToTwoDecimal(total))}</span>
    </>
  );
};

export const billableTimeEntriesBody = (params) => {
  const { rowData, props, onChangeBillable } = params || {};
  return (
    <>
      <span className="p-column-title text-break">Billable</span>
      <label className="switch">
        <input
          type="checkbox"
          checked={rowData.billable}
          name="non_billable"
          onChange={(e) => {
            let index = props.rowIndex;
            onChangeBillable(e, index);
          }}
        />{' '}
        <span className="slider round"></span>
      </label>
    </>
  );
};

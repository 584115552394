import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { listCasesAndLeads } from 'services/CaseServices';
import { listCasesForClientUsers } from 'services/fileManager/fileManagerServices';
import { useUserDetailsContext } from 'context/userDetailsContext';

export function useCaseListFilter(isClient, caseOnly = false) {
  const [initialData, setInitialData] = useState([{ label: 'Search For More Cases', value: 'tip' }]);
  const userContext = useUserDetailsContext();

  const loadCasesForClientPortal = (id, keyword = '') => {
    let obj = {
      target_user_type: 'contact',
      target_user_id: id,
      target_user_name: '',
      keyword,
    };
    return listCasesForClientUsers(obj)
      .then((res) => {
        let tempArray = [];
        res.data.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            case_name: item?.case_description?.case_name || 'TBD',
            practice_area: item?.case_description?.case_practice_area,
            id: item.case_id,
          });
        });
        return tempArray;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function fetchCaseList(is_lead = true, case_id = '', exclude_pi_case = false, keyword = '', limit = 15, is_filter_dropdown = true) {
    return listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((response) => {
        let cases = [...response.data?.case_list];
        if (is_lead) {
          cases = [...response.data?.case_list, ...response.data?.lead_list];
        }
        return cases.map((item) => {
          return {
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            case_name: item?.case_description?.case_name || 'TBD',
            practice_area: item?.case_description?.case_practice_area,
            id: item.case_id,
          };
        });
      })
      .catch((err) => {
        console.log(err.resopnse, 'errrrrr');
      });
  }

  useEffect(async () => {
    let result = [];
    if (!isClient) {
      result = await fetchCaseList(!caseOnly, '', false, '');
    } else if (userContext?.userDetails) {
      result = await loadCasesForClientPortal(userContext?.userDetails.contact_id);
    }
    setInitialData([{ label: 'Search For More Cases', value: 'tip' }, ...result]);
  }, [isClient, userContext?.userDetails.contact_id]);

  const onFromSearchChange = (search) =>
    new Promise(async (resolve, reject) => {
      try {
        let result = [];
        if (!isClient) {
          result = await fetchCaseList(!caseOnly, '', false, search, 15, true, true, false);
        } else if (userContext?.userDetails) {
          result = await loadCasesForClientPortal(userContext?.userDetails.contact_id, search);
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });

  // Debounced version of the function
  const debouncedOnFromSearchChange = debounce((inputValue, callback) => {
    onFromSearchChange(inputValue)
      .then(callback)
      .catch((error) => {
        console.error('Error fetching case list:', error);
        callback([]);
      });
  }, 500);

  return { initialCaseList: initialData, onSearchChange: debouncedOnFromSearchChange };
}

import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import WithdrawOrDepositTemporaryFund from './components/WithdrawOrDepositTemporaryFund/WithdrawOrDepositTemporaryFund';
import { dollarFormat, getPropByString } from 'utils/utils';
import { paginationDropdownOptions } from 'constants/dropdownOptions';
import { useCaseListFilter as useFilter } from 'modules/cases/hooks/useCaseListFilter';
import { useTemporaryFundList } from '../hooks/useTemporaryFundList';
import { useAccounts } from 'hooks/useAccounts';

const TemporaryFunds = (props) => {
  const { caseView, isLeadSpecific, caseDetails, isCaseClosed, isLeadClosed, getCaseDetails } = props || {};

  const trustAccountId = caseDetails?.default_trust_account?.length ? caseDetails?.default_trust_account?.[0]?.account_id : '';

  const { accountList } = useAccounts({ accountType: 'trust' });

  const { filter, filterOnChange, refreshList } = useFilter({
    case_id: caseView ? caseDetails?.case_id : null,
    account_id: trustAccountId,
  });

  const {
    onPageChange,
    fetchNext,
    loadTempFundList,
    loadTempAccountBalance,
    fetchprev,
    hasNext,
    hasPrevious,
    pageSize,
    temporaryFundBalance,
    temporaryFundsList,
    depositOrWithdrawTemporaryFundAmount,
    updateTemporaryFundTransactions,
  } = useTemporaryFundList({
    account_id: trustAccountId,
    case_id: caseDetails?.case_id,
    _filter: filter,
    getCaseDetails,
  });

  const [temporaryFundModalOptions, setTemporaryFundModalOptions] = useState({ show: false, isUpdate: false });

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={pageSize} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchNext} disabled={hasNext} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchprev} disabled={hasPrevious} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
  };

  const amountBody = (rowData) => {
    const { entry_type } = rowData;
    return (
      <div>
        <span className="p-column-title text-break">From trust</span>
        <div>
          <span className={entry_type === 'cr' ? 'text-success' : 'text-danger'}>{dollarFormat(rowData?.amount)}</span>
          {entry_type === 'cr' && <i className="fas fa-arrow-down ms-2"></i>}
          {entry_type !== 'cr' && <i className="fas fa-arrow-up ms-2"></i>}
          <br></br>
          {rowData?.trust_info?.length > 0 &&
            rowData?.trust_info.map((index) => {
              return (
                <span>
                  Account: {index.account_name}
                  <br></br>
                  Balance: {dollarFormat(index.trust_balance)}
                </span>
              );
            })}
        </div>
      </div>
    );
  };

  const fromOrToBody = (rowData) => {
    const { contact_details, entry_type } = rowData || {};
    return (
      <>
        <span className="p-column-title text-break">To/From account</span>
        <span className="text-muted F-size12">{entry_type === 'db' ? 'To' : 'From'}:</span>
        <span className="ms-2 primary-dark">{contact_details?.fullName}</span>
      </>
    );
  };

  const dateBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Date</span>
        <span className="text-break">
          {rowData?.record_created_date ? moment(rowData?.record_created_date?.split('T')?.[0]).format('MM/DD/YYYY') : '-'}
        </span>
      </React.Fragment>
    );
  };

  const actionBody = (rowData) => {
    return (
      <>
        {!rowData?.is_strikethrough && (
          <>
            <span className="p-column-title text-break">Action</span>
            <span className="d-inline-table">
              <i
                className={`icon-edit cursor-pointer ${isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'disabled-icon' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  setTemporaryFundModalOptions({
                    show: true,
                    entryType: rowData?.entry_type,
                    isUpdate: true,
                    selectedRowData: { ...rowData },
                  });
                }}
              />
            </span>
          </>
        )}
      </>
    );
  };

  const caseTemplate = (rowData, ...rest) => {
    const { header, field } = rest?.[0];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">{header}</span>
        <span className="text-break">
          <Link className="cursor-pointer text-decoration-none F-size14 text-bold" to={'/cases/' + rowData?.case_id}>
            {getPropByString(rowData, field)}
          </Link>
        </span>
      </React.Fragment>
    );
  };

  const rowClassName = (rowData) => ({ strikethrough: rowData.is_strikethrough });

  const accountBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Account</span>
        <span className="text-break">
          <Link to="/settings/accounts" className="text-bold text-decoration-none">
            {rowData?.credit_account_name || rowData?.debit_account_name}
          </Link>
        </span>
      </React.Fragment>
    );
  };

  const refreshData = () => {
    refreshList({
      case_id: caseView ? caseDetails?.case_id : null,
    });
  };

  useEffect(() => {
    loadTempFundList({});
    loadTempAccountBalance(filter?.account_id || trustAccountId, caseDetails?.case_id);
  }, [filter, trustAccountId]);

  return (
    <>
      {caseView && (
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Button
            label="Deposit"
            className="p-button p-button-primary"
            onClick={() => {
              setTemporaryFundModalOptions({ show: true, entryType: 'cr', isUpdate: false });
            }}
            disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
          />
          <Button
            label="Withdraw"
            className="p-button p-button-primary ms-2"
            onClick={() => {
              setTemporaryFundModalOptions({ show: true, entryType: 'db', isUpdate: false });
            }}
            disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
          />
        </div>
      )}
      <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-lg-0 p-2 w-100 align-items-center justify-content-between">
        <div className="ms-2 ">
          {(filter?.account_id || caseView) && (
            <>
              Balance: <span className="text-bold black-600 ">{dollarFormat(temporaryFundBalance)}</span>
            </>
          )}
        </div>

        <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
          <label className="text-bold black-600 p-0 m-0 filter-label">Filter:</label>
          <div className="d-flex flex-column ms-2 filter-wraper">
            <Calendar
              className="input-shadow filter-calender"
              id="icon"
              name="dateFilter"
              selectionMode="range"
              value={filter?.dateFilter}
              onChange={filterOnChange}
              showIcon
              placeholder="Date"
              maxDate={new Date()}
            />
          </div>
          {!caseView && (
            <div className="d-flex flex-column ms-2 filter-wraper">
              <Dropdown
                placeholder="By Account"
                name="account_id"
                options={accountList}
                value={filter?.account_id}
                onChange={filterOnChange}
                optionLabel={'account_name'}
                optionValue="account_id"
                className="w-100"
                filter
                showClear
              />
            </div>
          )}
          <div className="d-flex flex-column mx-2">
            <div className="pointer ms-0">
              <i className="fas fa-sync-alt" onClick={refreshData}></i>
              <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 datatable-responsive">
          <DataTable
            value={temporaryFundsList}
            rowClassName={rowClassName}
            className="p-datatable-responsive"
            paginatorTemplate={paginatorTemplate}
            paginator
          >
            {!caseView && <Column header="Case Name" field="case_name" body={caseTemplate} />}
            <Column header="Date of Transaction" field="record_created_date" body={dateBody} />
            {!caseView && <Column header="Account" body={accountBody} />}
            <Column header="Amount" body={amountBody} />
            <Column header="Transferred From/To" body={fromOrToBody} />
            {caseView && <Column header="Action" body={actionBody} />}
          </DataTable>
        </div>
      </div>
      {temporaryFundModalOptions?.show && (
        <WithdrawOrDepositTemporaryFund
          onHide={() => setTemporaryFundModalOptions({ show: false })}
          caseDetails={caseDetails}
          temporaryFundModalOptions={temporaryFundModalOptions}
          depositOrWithdrawTemporaryFundAmount={depositOrWithdrawTemporaryFundAmount}
          updateTemporaryFundTransactions={updateTemporaryFundTransactions}
        />
      )}
    </>
  );
};

export default TemporaryFunds;

import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import LabelName from 'components/UI/LabelName/LabelName';
import { reminderChannels, reminderCountOptions, reminderCustomOptions, reminderOptions } from 'constants/dropdownOptions';

function EventReminders({ watch, control, isGlobal }) {
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'reminder',
  });

  function onAddReminder() {
    insert(fields.length, { value: '30#minutes#', reminder_channel_type: 'inapp_notification' });
  }
  function onDeleteReminder(index) {
    remove(index);
  }

  const caseId = watch('case') || watch('case_id');

  return (
    <div className="d-flex py-2 flex-wrap w-100">
      <div className="col-md-4 col-12 mt-2">
        <LabelName required={false}>Reminders</LabelName>
      </div>
      <div className="col-md-8 col-12">
        {fields?.length > 0 &&
          fields.map((data, index) => {
            let reminderValue = watch(`reminder.${index}.value`);
            return (
              <div className="d-flex input-shadow align-items-center justify-content-center p-2 mb-2 bg-grey" style={{ borderRadius: 12 }}>
                <div className="w-100" key={index}>
                  <div>
                    <Controller
                      name={`reminder.${index}.reminder_channel_type`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Channel"
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          options={
                            reminderChannels?.filter((item) => !(item?.value === 'secure_message' && !Boolean(caseId) && isGlobal)) || []
                          }
                          className="w-100"
                          filter
                        />
                      )}
                    />
                  </div>
                  <div className="mt-2">
                    <Controller
                      name={`reminder.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Add"
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          options={reminderOptions}
                          className="w-100"
                          filter
                        />
                      )}
                    />
                  </div>

                  {reminderValue === 'custom' && (
                    <div className="d-flex mt-2 gap-2">
                      <Controller
                        name={`reminder.${index}.customValue`}
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                          <Dropdown
                            placeholder="Add"
                            name={field.name}
                            id={field.name}
                            className="w-100"
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={reminderCountOptions}
                            filter
                          />
                        )}
                      />

                      <Controller
                        name={`reminder.${index}.type`}
                        control={control}
                        defaultValue="minutes"
                        render={({ field }) => (
                          <Dropdown
                            placeholder="Select Type"
                            name={field.name}
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={reminderCustomOptions}
                            filter
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <div
                  onClick={(e) => {
                    onDeleteReminder(index);
                  }}
                  className="pointer"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <i className="pi icon-delete"></i>
                  <Tooltip content="Delete Reminder" position="top" target=".icon-delete" showDelay={500} />
                </div>
              </div>
            );
          })}
        <div className="mt-2 ">
          <span className="pointer text-primary" onClick={onAddReminder}>
            + Add Reminder
          </span>
        </div>
      </div>
    </div>
  );
}

export default EventReminders;

import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Auth } from 'aws-amplify';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import CaseName from './CaseName';
import Directories from './Directories';
import NotLinkedToCase from './NotLinkedToCase';
import UploadInput from './UploadInput';
import FilesList from './FilesList';

import { useToast } from 'context/ToastContext';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

import { createFile, updateFile } from 'services/fileManager/fileManagerServices';
import { toastConstant } from 'constants/toastmessage';
import { useNavbarContext } from 'context/NavbarContext';

function FileUploadModal() {
  const {
    modals,
    caseDetails,
    fileManagerFilters,
    selectedItem,
    isGlobalFileManager,
    isCaseFileManager,
    isLeadFileManager,
    isClientFileManager,
    handleGetFiles,
    pageLimit,
    onMountUnMount,
    onOperationBreak,
    versionFileUpload,
  } = useFileManagerContext();
  const { showFileUploadModal, setShowFileUploadModal } = modals;
  const [uploadingIndex, setUploadingIndex] = useState('');

  const { startUpload } = useNavbarContext();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    resetField,
  } = useForm();

  const notLinkedToCaseWatcher = watch('not_linked_to_case');
  const filesWatcher = watch('files');

  const { addToast } = useToast();

  //Automated Time entires logic
  useEffect(() => {
    isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('upload-file', 'mounted');
  }, []);

  async function uploadFiles(data) {
    try {
      startUpload(data?.files || [], undefined, async (files = []) => {
        if (files?.length === 0) return;
        let attachments = [];
        let user = await Auth.currentAuthenticatedUser();
        files.forEach((file) => {
          const version_id = file?.VersionId || '';
          if (version_id) {
            const file_details = {
              file_name: file?.file.name,
              display_name: file?.file.name,
              size: file?.file?.size.toString(),
              file_path: file?.Key,
              file_type: file?.file?.type?.toString(),
              created_by: user.attributes,
              version_id: version_id,
            };

            if (selectedItem) {
              if (!selectedItem.versions) {
                selectedItem.versions = [];
              }
              selectedItem.versions.push({
                file_name: selectedItem.file_name,
                display_name: selectedItem.display_name,
                size: selectedItem.size,
                file_path: selectedItem.file_path,
                file_type: selectedItem.file_type,
                created_by: selectedItem.user?.attributes,
                version_id: selectedItem.version_id,
                version_edited: new Date().toString(),
              });

              selectedItem.file_name = file?.file?.name;
              selectedItem.display_name = file?.file?.name;
              selectedItem.version_id = version_id;
              selectedItem.created_by = user.attributes;
              selectedItem.file_type = file?.file?.type?.toString();
              selectedItem.file_path = file?.Key;
              selectedItem.size = file?.file?.size.toString();
            } else {
              attachments.push({
                ...file_details,
                versions: [],
                complete_path: file?.file.path?.replace(/^\/+/, ''),
              });
            }
          }
        });

        if (selectedItem) {
          updateFileHandler(data, versionFileUpload ? selectedItem : attachments);
        } else {
          createFileHandler(data, attachments);
        }
      });
      setShowFileUploadModal(false);
    } catch (error) {
      if (error?.message === 'Network Error') {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.NETWORK_ERROR);
        return;
      }
      addToast(
        false,
        toastConstant.toasterType.ERROR,
        toastConstant.api.ERROR,
        error?.response?.status === 403 ? error.response?.data : toastConstant.message.FILE_UPLOAD_FAILED
      );
      //Automated Time entires logic
      isCaseFileManager && typeof onOperationBreak === 'function' && onOperationBreak();
    }
  }

  function createFileHandler(data, attachments) {
    let case_id;

    if (data && data.case && data.case.id) {
      case_id = data.case.id;
    } else if (isClientFileManager) {
      case_id = fileManagerFilters.case;
    } else if (caseDetails && caseDetails.case_id !== undefined) {
      case_id = caseDetails.case_id;
    } else {
      case_id = 'undefined';
    }

    let dataObj = {
      case_id: case_id,
      type: 'bulk_upload',
      folder_id: fileManagerFilters.selectedFolder,
      file_folder: attachments,
      not_linked_to_case: data.not_linked_to_case,
      is_lead: isLeadFileManager,
    };
    let obj = {
      data: dataObj,
      is_lead: isLeadFileManager,
    };
    createFile(obj)
      .then(() => {
        handleGetFiles(pageLimit);
        setShowFileUploadModal(false);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILES_UPLOADED);
        //Automated Time entires logic
        isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('upload-file', 'unmounted');
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_UPLOAD_FAILED);
      });
    setUploadingIndex('');
  }

  function updateFileHandler(data, updatedData) {
    let case_id;

    if (data && data.case && data.case.id) {
      case_id = data.case.id;
    } else if (isClientFileManager) {
      case_id = fileManagerFilters.case;
    } else if (caseDetails && caseDetails.case_id !== undefined) {
      case_id = caseDetails.case_id;
    } else {
      case_id = 'undefined';
    }

    updatedData.case_id = case_id;

    let obj = {
      data: updatedData,
      is_lead: isLeadFileManager,
    };
    updateFile(obj)
      .then(() => {
        setShowFileUploadModal(false);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILES_UPLOADED);
        handleGetFiles(pageLimit);
        //Automated Time entires logic
        isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('upload-file', 'unmounted');
      })
      .catch((error) => {
        console.log(error);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_UPLOAD_FAILED);
      });
    setUploadingIndex('');
  }

  const { getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => setValue('files', acceptedFiles),
  });

  return (
    <div className="file-upload-sidebar" {...getRootProps()}>
      <Sidebar
        visible={showFileUploadModal}
        style={{ width: '40em' }}
        onHide={() => setShowFileUploadModal(false)}
        className="file-manager-sidebar"
        position="right"
      >
        <div className="file-upload-header d-flex justify-content-between align-items-center py-2 my-3">
          <div className="pointer">
            <i
              onClick={() => {
                setShowFileUploadModal(false);
              }}
              className="icon-back me-2 p-2"
            ></i>
            <label className="sub-title">Upload</label>
          </div>
        </div>
        <br />
        <div className="file-upload-container d-flex flex-column justify-content-between">
          {(isGlobalFileManager || isClientFileManager) && (
            <CaseName control={control} notLinkedToCaseWatcher={notLinkedToCaseWatcher} errors={errors} setValue={setValue} />
          )}
          <Directories />
          {isGlobalFileManager && !fileManagerFilters.case && <NotLinkedToCase control={control} errors={errors} resetField={resetField} />}
          <UploadInput control={control} errors={errors} />

          <div className="d-flex justify-content-end mt-3">
            <Button label="Cancel" className="p-button  p-button-secondary outline ms-2" onClick={(e) => setShowFileUploadModal(false)} />{' '}
            <Button label="Upload" className="p-button p-button-secondary ms-2" onClick={handleSubmit(uploadFiles)} />
          </div>
        </div>
        <br />
        <FilesList filesWatcher={filesWatcher} uploadingIndex={uploadingIndex} setValue={setValue} />
      </Sidebar>
    </div>
  );
}

export default FileUploadModal;
